import React from "react";
import { PageProps } from "gatsby";
import { Section } from "components/Section";
import { HeaderPlaceholder } from "components/Header";

import withI18n from "i18n/withI18n";
import SEO from "components/seo";
import { Layout } from "components/Layout";

import { PrivacyPolicy } from "components/Footer/Policy";

const NotFoundPage = ({ location }: PageProps) => {
  return (
    <>
      <SEO title="Privacy Policy" pathname={location.pathname} locale />
      <Layout>
        <HeaderPlaceholder />
        <Section className="my-20">
          <PrivacyPolicy />
        </Section>
      </Layout>
    </>
  );
};

export default withI18n(NotFoundPage);
